<template>
    <div id="app">
        <router-view />
    </div>
</template>
<script>
export default {
    name: 'App',
    data(){
        return {
          screen:false,
        }
    },
    created () {
        //在页面加载时读取sessionStorage里的状态信息
        if (localStorage.getItem("store") ) {
            this.$store.replaceState(Object.assign({}, this.$store.state,JSON.parse(localStorage.getItem("store"))))
        }

        //在页面刷新时将vuex里的信息保存到sessionStorage里
        window.addEventListener("beforeunload",()=>{
            localStorage.setItem("store",JSON.stringify(this.$store.state))
        })
        // 根据环境变量 配置环境参数, 默认是本地开发环境
        const env = process.env.NODE_ENV;
        if(env === 'production'){
            !function test(){
                // 捕获异常，递归次数过多调试工具会抛出异常。
                try { !
                    function cir(i) {
                        // 当打开调试工具后，抛出异常，setTimeout执行test无参数，此时i == NaN，("" + i / i).length == 3
                        // debugger设置断点
                        (1 !== ("" + i / i).length || 0 === i) &&
                        function() {}.constructor("debugger")(),
                            cir(++i);
                    } (0)
                } catch(e) {
                    setTimeout(test, 500)
                }
            }()
        }
    },
  methods:{
    }
}
</script>
<style lang="less">
html,body,#app {height: 100%; margin: 0; padding: 0; font-size: 12px; font-family: 'Avenir',Helvetica,Arial,sans-serif;}
</style>
