<template>
    <div class="layout-device-list">
        <!--s: Search 区域-->
        <div class="device-list-search">
            <div class="device-list-select">
<!--                <div class="box">-->
<!--                    <span class="label">分类：</span>-->
<!--                    <Cascader style="width: 180px"  :data="classifyMenuArray" v-model="deviceListParams.deviceType"></Cascader>-->
<!--                </div>-->
                <div class="box">
                    <span class="label">生产厂家：</span>
                    <Select v-model="deviceListParams.manufacturer" clearable style="width: 180px">
                        <Option v-for="(manufacturer,key) in manufacturerArray" :value="manufacturer.id" :key="key">{{ manufacturer.name }}</Option>
                    </Select>
                </div>
                <div class="box">
                    <span class="label">查找：</span>
                    <Input prefix="ios-search" v-model="deviceListParams.search" placeholder="设备编号、设备名称、型号" style="width: 300px" />
                </div>
                <div class="box" v-if="windowWidth>=1360">
                    <Button @click="onReset">重置</Button>
                    <Button type="primary" @click="onSearch">搜索</Button>
                </div>
            </div>
            <div class="device-btn">
<!--                <Button custom-icon='ios-qr-code-black' class="code">下载二维码</Button>-->
<!--                <Button icon="ios-trash-outline" @click="onRemoveList">删除</Button>-->
                <Button type="primary" icon="ios-add" @click="onAddList" class="right">新增</Button>

                <Button type="primary" v-if="windowWidth<1360" @click="onSearch" class="right">搜索</Button>
                <Button @click="onReset" v-if="windowWidth<1360" class="right">重置</Button>
            </div>
        </div>
        <!--e: Search 区域-->
        <!--s: Data 区域-->
        <div class="device-list">
            <div class="table">
                <Table ref="selection" stripe="stripe" :height="deviceListHeight" :loading="deviceLoading" :columns="deviceListColumns" :data="deviceListArray.content"  @on-select-all="onSelectAll" @on-select-all-cancel="onSelectAllCancel" @on-select="onSelect" @on-select-cancel="onSelectCancel">
    <!--                <template slot-scope="{ row }" slot="设备编号">-->
    <!--                    <a href="javascript:" class="device-number" @click="onLinkInfo(row.id)">{{ row.serialNumber }}</a>-->
    <!--                </template>-->
                    <template slot-scope="{ row }" slot="设备信息">
                        <div class="device-info" @click="onLinkInfo(row,6)">
                            <img v-if="row.equipmentPictures" :src="row.equipmentPictures" class="image"/>
                            <div class="image-error" v-if="!row.equipmentPictures">
                                <div class="box">
                                    <span class="table-image"><Icon type="ios-images" size="25"/><font >未添加图片</font></span>
                                </div>
                            </div>
                            <div class="device-text">
                                <div class="box">
                                    <span class="name">{{ row.equipmentName }}</span>
    <!--                                <span class="type">{{ row.classificationName }}</span>-->
                                    <div class="info">
                                        <span class="text">设备型号：{{ row.modelName }}</span>
                                        <span class="text">生产厂家：{{ row.manufacturerName }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template slot-scope="{ row }" slot="设备简介">
                        <a href="javascript:" :disabled="row.introSize === 0" @click="onLinkInfo(row,7)"><Icon :custom="row.introSize === 0?'icon-intro-grey':'icon-intro-blue'" /></a>
                    </template>
                    <template slot-scope="{ row }" slot="技术参数">
                        <a href="javascript:" :disabled="row.paramSize === 0" @click="onLinkInfo(row,9)"><Icon :custom="row.paramSize === 0?'icon-parameter-grey':'icon-parameter-blue'" /></a>
                    </template>
<!--                    <template slot-scope="{ row }" slot="结构部件">-->
<!--                        <a href="javascript:" :disabled="row.partsSize === 0" @click="onLinkInfo(row,4)"><Icon :custom="row.partsSize === 0?'icon-structural-grey':'icon-structural-blue'" /></a>-->
<!--                    </template>-->
<!--                    <template slot-scope="{ row }" slot="工作原理">-->
<!--                        <a href="javascript:" :disabled="row.principleSize === 0" @click="onLinkInfo(row,8)"><Icon :custom="row.principleSize === 0?'icon-principle-grey':'icon-principle-blue'" /></a>-->
<!--                    </template>-->
<!--                    <template slot-scope="{ row }" slot="使用维护">-->
<!--                        <a href="javascript:" :disabled="row.instructionUseFileSize === 0" @click="onLinkInfo(row,1)"><Icon :custom="row.instructionUseFileSize === 0?'icon-instruction-grey':'icon-instruction-blue'" /></a>-->
<!--                    </template>-->
                    <template slot-scope="{ row }" slot="故障解析">
                        <a href="javascript:" :disabled="row.faultAnalysisSize === 0" @click="onLinkInfo(row,5)"><Icon :custom="row.faultAnalysisSize === 0?'icon-fault-grey':'icon-fault-blue'" /></a>
                    </template>
                    <template slot-scope="{ row }" slot="机械图册">
                        <a href="javascript:" :disabled="row.mechanicalAtlasFileSize === 0" @click="onLinkInfo(row,2)"><Icon :custom="row.mechanicalAtlasFileSize === 0?'icon-mechanical-grey':'icon-mechanical-blue'"/></a>
                    </template>
                    <template slot-scope="{ row }" slot="电气图册">
                        <a href="javascript:" :disabled="row.electricalAtlasFileSize === 0" @click="onLinkInfo(row,3)"><Icon :custom="row.electricalAtlasFileSize === 0?'icon-electrical-grey':'icon-electrical-blue'"/></a>
                    </template>
                    <template slot-scope="{ row }" slot="文件资料">
                        <a href="javascript:" :disabled="row.otherUseFileSize === 0" @click="onLinkInfo(row,0)"><Icon :custom="row.otherUseFileSize === 0?'icon-other-grey':'icon-other-blue'"/></a>
                    </template>
                    <template slot="操作" slot-scope="{ row }">
                        <div class="handle">
                            <a href="javascript:" @click="onCode(row)">二维码</a>
                            <a href="javascript:" @click="onDelete(row.id)">删除</a>
                        </div>
                    </template>
                </Table>
            </div>
            <div class="device-list-page" v-if="deviceListArray.content && deviceListArray.content.length !== 0">
                <Page :total="deviceListArray.totalSize" :current="deviceListParams.currentPage" show-total show-sizer size="small" :page-size="deviceListParams.displayNumber" @on-change="onSearchPage" @on-page-size-change="onSearchPageSize"/>
            </div>
        </div>
        <!--e: Data 区域-->
        <!--s: Code modal-->
        <Modal
            v-model="qrCodeVisible"
            title="二维码"
            :width="600"
            class-name="task-modal"
            footer-hide>

            <div class="task-code">
                <div class="box">
                    <div class="code" id="qrcode" ref="deviceRef">
                        <vue-qr :logoSrc="logoSrc" :text="QRCode.toString()" :size="500" class="layout-qr-code"></vue-qr>
                        <span class="name">设备名称：{{ QRObj.equipmentName }}</span>
                        <span class="name">生产厂家：{{ QRObj.manufacturerName }}</span>
                        <span class="name">设备型号：{{ QRObj.modelName }}</span>
                    </div>
                    <div class="down-load">
                        <a href="javascript:" @click="onDowns"><Icon custom="image upload" />下载二维码</a>
                    </div>
                </div>
            </div>
        </Modal>
        <!--e: Code modal-->
    </div>
</template>
<script>
import List from './list'
export default List
</script>
<style lang="less">
@import "list";
</style>
