import { getUploadConfig, sysncUploadFilesService,asynUploadFilesService } from "@/service/system-service";
import OSS from 'ali-oss'
import { decrypt } from '@/utils/crypto'
import { Message } from "view-design";
import { onGuid } from '@/utils/tools'
import $event, { EVENT_NAME } from "@/utils/event-emitter";


/**
 * 拉取上传参数配置
 * @param fileNames
 * @param type
 * @returns {Promise<void>}
 */
export const onUploadConfig = async (files,fileNames,route) => {
    try {
        const  {code,data}  = await getUploadConfig()
        if(code === 'SUCCESS'){
            return await onOssUpload(files,decrypt(data),fileNames,route)
        }else{
            Message.error('请求异常，请联系管理员！')
        }
    }catch (error){
        console.log(error)
    }
}

/**
 * OSS 上传方式
 * @param files
 * @param data
 * @param fileNames
 * @returns {Promise<void>}
 */
export const onOssUpload = async (files,data,fileNames,route) =>{
    const env = process.env.NODE_ENV;  //获取环境变量 以便上传到当前服务文件夹
    let client = new OSS({
        accessKeyId:data.accessKeyId,
        accessKeySecret:data.accessKeySecret,
        stsToken:data.stsToken,
        bucket:data.bucket,
        endpoint:data.endpoint,
        region:data.region,
        cname:data.cname,
        secure:data.secure,
    })
    return await client.multipartUpload(data.bucket+'/'+(env === 'production'?'pro':'pre')+'/'+route+'/'+onGuid()+'_'+fileNames,files,{
        progress,
    });
}

/**
 * 上传进度
 * @param speed
 * @returns {*}
 */
export const progress = (speed) => {
    return parseInt(speed * 100)
};

/**
 * 调用同步上传到存储服务器
 * @param files
 * @returns {Promise<void>}
 */
export const onSysncUploadFiles = async (files,type) =>{
    try{
        let params =  new FormData()
        for(let i in files){
            params.append('files',files[i])
        }
        params.append('type',type)
        let config = {
            onUploadProgress: progressEvent => {
                $event.$emit(EVENT_NAME.UPLOAD_PROGRESS_COMPLETE,Math.round((progressEvent.loaded / progressEvent.total ) * 100) -1)
            },
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        };
        const { code,data } = await sysncUploadFilesService(params,config)
        if(code === 'SUCCESS'){
            return data
        }else{
            Message.error('请求异常，请联系管理员！')
        }
    }catch (error){
        console.log(error)
    }
}
/**
 * 调用异步上传到存储服务器
 * @param files
 * @returns {Promise<void>}
 */
export const onAsynUploadFiles = async (files,type) =>{
    try{
        let params =  new FormData()
        for(let i in files){
            params.append('files',files[i])
        }
        params.append('type',type)
        let config = {
            onUploadProgress: progressEvent => {
                $event.$emit(EVENT_NAME.UPLOAD_PROGRESS_COMPLETE,Math.round((progressEvent.loaded / progressEvent.total ) * 100) -1)
            },
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        };
        const { code,data } = await asynUploadFilesService(params,config)
        if(code === 'SUCCESS'){
            return data
        }else{
            Message.error('请求异常，请联系管理员！')
        }
    }catch (error){
        console.log(error)
    }
}
