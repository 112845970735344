import Vue from 'vue';
import VueRouter from 'vue-router';
import Cookies from "js-cookie";
const Login = () => import('@/views/Login/login.vue');
const Index = () => import('@/views/Index/index.vue');
const BlankPage = () => import('@/views/BlankPage/blankPage.vue');
const Device = () => import('@/views/Device/device.vue');
const DeviceList = () => import('@/views/Device/List/list.vue');
const NewlyAdded = () => import('@/views/Device/NewlyAdded/newlyAdded.vue');
const DeviceInfo = () => import('@/views/Device/Info/info.vue');
const Account = () => import('@/views/Account/account.vue');
const AccountList = () => import('@/views/Account/List/list.vue');
const BusinessList = () => import('@/views/Business/business.vue');
const Process = () => import('@/views/Workflow/Process/process.vue');
const ProcessList = () => import('@/views/Workflow/Process/List/list.vue');
const ProcessConfig = () => import('@/views/Workflow/Process/Config/config.vue');
const TaskList = () => import('@/views/Workflow/Task/task.vue');
const Guidance = () => import('@/views/Workflow/Guidance/task.vue');
const RecordList = () => import('@/views/Workflow/Record/List/list.vue');
const RecordInfo = () => import('@/views/Workflow/Record/Info/info.vue');
const Electrical = () => import('@/views/Device/Electrical/electrical.vue');
const EditTask = () => import('@/views/Workflow/EditTask/editTask.vue');

Vue.use(VueRouter)
/**
 * 报错显示路由频繁点击导致路由重复而报错，该报错对路由跳转功能没有任何影响。
 * @type {{}}
 */
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
    return originalPush.call(this, location).catch(err => err)
}
const routes = [
    {
        path: '/login',
        name: 'Login',
        component: Login,
    },
    {
        path: '/',
        name: 'Index',
        component: Index,
        redirect: { name: "BlankPage" },
        children:[
            {
                path:'',
                name:'BlankPage',
                component:BlankPage
            },
            {
                path: '/device',
                name:'Device',
                component: Device,
                redirect: { name: "BlankPage" },
                children:[
                    {
                        path:'',
                        name:'DeviceBlankPage',
                        component:BlankPage
                    },
                    {
                        path: '/device/list',
                        name:'DeviceList',
                        component: DeviceList,
                    }
                ]
            },
            {
                path:'/device/add',
                name:'NewlyAdded',
                component:NewlyAdded
            },
            {
                path:'/device/info',
                name:'DeviceInfo',
                component:DeviceInfo
            },
            {
                path:'/account',
                name:'Account',
                component:Account,
                redirect: { name: "AccountList" },
                children:[
                    {
                        path: '/account/list',
                        name:'AccountList',
                        component: AccountList,
                    }
                ]
            },
            {
                path:'/business',
                name:'BusinessList',
                component:BusinessList
            },
            {
                path:'/workflow/task',
                name:'TaskList',
                component:TaskList
            },
            {
                path:'/workflow/guidance/task',
                name:'Guidance',
                component:Guidance
            },
            {
                path:'/workflow/edit/task',
                name:'EditTask',
                component:EditTask
            },
            {
                path: '/workflow/process',
                name:'Process',
                component: Process,
                redirect: { name: "ProcessList" },
                children:[
                    {
                        path: '/workflow/process/list',
                        name:'ProcessList',
                        component: ProcessList,
                    }
                ]
            },
            {
                path:'/workflow/record/list',
                name:'RecordList',
                component:RecordList
            },
            {
                path:'/workflow/record/info',
                name:'RecordInfo',
                component:RecordInfo
            },
        ]
    },
    {
        path: '/workflow/config',
        name: 'ProcessConfig',
        component: ProcessConfig,
    },
    {
        path:'/device/electrical/config',
        name:'Electrical',
        component:Electrical
    },
]
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})
router.beforeEach((to, from, next) => {
    document.title = '灵云AR智能服务平台'
    if (to.matched.length === 0) { //匹配前往的路由不存在
        from.name ? next({
            name: from.name
        }) : next('/'); //判断此跳转路由的来源路由是否存在，存在的情况跳转到来源路由，否则跳转到首页
    } else {
        next()
        const isLogin = Cookies.get('token') //判断是否登录
        if (!isLogin) {
            if (to.name === 'Login') {
                next()
            } else {
                next('/login')
            }
        } else {
            if (to.name === 'Login') {
                next('/')
            } else {
                next()
            }
        }
    }
});
export default router
