export default {
    namespaced: true,
    state: {
        classifyMenuArray:[], //分类菜单数据
        selectedMenuId:[],//选中的菜单Id
        menuVisible:true, //菜单显示状态
        deviceInfo:{}, //设备信息
        structuralInfo:{},
        taskId:'', //任务id
        flowId:'', //流程id
        taskList:{}, //任务数据
        tabId:1, //tabId
        electricalId:'',//电气id
        taskTypeId:'', //分类id
        taskInspectionTypeId:'', //巡检分类id
        structuralId:'', //结构部件id
        taskType:1,
        taskEdit:{},
        fileSelect:[],//文件选择层级
    },
    mutations: {
        /**
         * 分类菜单数据
         * @param state
         * @param clientSide
         * @constructor
         */
        SET_CLASSIFY_MENU_ARRAY (state,classifyMenuArray) {
            state.classifyMenuArray = classifyMenuArray;
        },
        /**
         * 选中的菜单Id
         * @param state
         * @param selectedMenuId
         * @constructor
         */
        SET_SELECTED_MENU_ID (state,selectedMenuId) {
            state.selectedMenuId = selectedMenuId;
        },
        /**
         * 菜单显示状态
         * @param state
         * @param menuVisible
         * @constructor
         */
        SET_MENU_VISIBLE (state,menuVisible){
            state.menuVisible = menuVisible;
        },
        /**
         * 设备信息
         * @param state
         * @param deviceId
         * @constructor
         */
        SET_DEVICE_INFO (state,deviceInfo){
            state.deviceInfo = deviceInfo;
        },
        /**
         * 结构部件信息
         * @param state
         * @param deviceId
         * @constructor
         */
        SET_STRUCTURAL_INFO (state,structuralInfo){
            state.structuralInfo = structuralInfo;
        },
        /**
         * 任务id
         * @param state
         * @param taskId
         * @constructor
         */
        SET_TASK_ID (state,taskId){
            state.taskId = taskId;
        },
        /**
         * 流程id
         * @param state
         * @param flowId
         * @constructor
         */
        SET_FLOW_ID (state,flowId){
            state.flowId = flowId;
        },
        /**
         * 任务数据
         * @param state
         * @param taskList
         * @constructor
         */
        SET_TASK_LIST (state,taskList){
            state.taskList = taskList;
        },
        /**
         * tabId
         * @param state
         * @param tabId
         * @constructor
         */
        SET_TAB_ID (state,tabId){
            state.tabId = tabId;
        },
        /**
         * 电气id
         * @param state
         * @param electricalId
         * @constructor
         */
        SET_ELECTRICAL_ID (state,electricalId){
            state.electricalId = electricalId;
        },
        /**
         * 电气id
         * @param state
         * @param taskTypeId
         * @constructor
         */
        SET_TASK_TYPE_ID (state,taskTypeId){
            state.taskTypeId = taskTypeId;
        },
        /**
         * 电气id
         * @param state
         * @param taskInspectionTypeId
         * @constructor
         */
        SET_TASK_INSPECTION_TYPE_ID (state,taskInspectionTypeId){
            state.taskInspectionTypeId = taskInspectionTypeId;
        },
        /**
         * 结构部件id
         * @param state
         * @param structuralId
         * @constructor
         */
        SET_STRUCTURAL_ID (state,structuralId){
            state.structuralId = structuralId;
        },
        /**
         * 任务分类
         * @param state
         * @param taskType
         * @constructor
         */
        SET_TASK_TYPE (state,taskType){
            state.taskType = taskType;
        },
        /**
         * 任务编辑
         * @param state
         * @param taskEdit
         * @constructor
         */
        SET_TASK_EDIT (state,taskEdit){
            state.taskEdit = taskEdit;
        },
        /**
         * 文件选择层级
         * @param state
         * @param fileSelect
         * @constructor
         */
        SET_FILE_SELECT(state,fileSelect){
            state.fileSelect = fileSelect;
        },
    },
}
