<template>
    <div class="layout-task-add">
        <div class="layout-device-bread">
            <div class="list-back"><a href="javascript:" @click="onBack"><Icon type="ios-log-out" size="20"/>退出</a></div>
        </div>
        <div class="layout-device-li" :style="'height:'+editTaskHeight+'px'">
            <div class="box">
                <ul>
                    <li class="item">
                        <span class="label required"><font>*</font>任务分类：</span>
                        <Cascader v-if="taskTypeListArray" style="width: 300px" :data="taskTypeListArray" v-model="taskParams.classificationId"></Cascader>
                    </li>
                    <li class="item">
                        <span class="label required"><font>*</font>任务名称：</span>
                        <Input v-model="taskParams.tasksName" placeholder="请输入" clearable style="width: 300px" />
                    </li>
                    <li class="item">
                        <span class="label required"><font>*</font>引用流程：</span>
                        <Select v-model="taskParams.flowId" :loading="flowLoading" filterable clearable @on-open-change="onOpenChange" style="width: 300px">
                            <Option v-for="item in workflowListArray" :value="item.id" :key="item.id">{{ item.flowName }}</Option>
                        </Select>
                    </li>
                    <li class="item">
                        <span class="label required"><font>*</font>执行周期
                            <Poptip trigger="hover" placement="bottom">
                                <Icon type="ios-information-circle" size="20" style="color: #a0a0a0"/>
                                <template #content>
                                    <div class="cycle-title">
                                        <ul>
                                            <li>
                                                <h1 class="title">一次性</h1>
                                                <span class="cycle-con">只需要完成一次任务。</span>
                                            </li>
                                            <li>
                                                <h1 class="title">永久性</h1>
                                                <span class="cycle-con">可重复执行的任务。</span>
                                            </li>
                                            <li>
                                                <h1 class="title">周期性</h1>
                                                <span class="cycle-con">根据执行周期定时发布任务。</span>
                                            </li>
                                        </ul>
                                    </div>
                                </template>
                            </Poptip>：
                        </span>
                        <div class="circle-body">
                            <div class="circle-check">
                                <RadioGroup v-model="taskParams.executeCycle" @on-change="onRadioChange">
                                    <Radio :label="item.typeCode" :key="key" v-for="(item,key) in taskCycleArray">{{ item.typeName }}</Radio>
                                </RadioGroup>
                            </div>
                            <div class="circle-only" v-if="taskParams.executeCycle === 1">
                                <DatePicker type="datetimerange" v-model="pickerValue" :options="onOptions" format="yyyy-MM-dd HH:mm" placeholder="请选择日期区间" style="width: 300px" @on-change="onTimeChange" @on-clear="onClearTime"/>
<!--                                <div class="time">-->
<!--                                  <Icon type="ios-time-outline" size="16" />{{ taskParams.startTime }} <span class="text">至</span> {{ taskParams.endTime }}-->
<!--                                </div>-->
<!--                                <DatePicker-->
<!--                                    :open="timeOpen"-->
<!--                                    :options="onOptions"-->
<!--                                    v-model="pickerValue"-->
<!--                                    confirm-->
<!--                                    format="yyyy-MM-dd HH:mm"-->
<!--                                    type="datetimerange"-->
<!--                                    @on-clear="timeOpen = false"-->
<!--                                    @on-ok="timeOpen = false">-->
<!--                                    <a href="javascript:" @click="timeOpen = !timeOpen">-->
<!--                                        修改设置-->
<!--                                    </a>-->
<!--                                </DatePicker>-->
                            </div>
                            <div class="circle-date" v-if="taskParams.executeCycle === 2">
                                <div class="time">
                                    {{ pickerValue[0] }}开始； {{ taskParams.executeNum }}次/{{ taskParams.cycleName.slice(-1) }}
                                    <a href="javascript:" class="time-update" @click="circleVisible = true">修改设置</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="item">
                        <span class="label">执行人：</span>
<!--                        <div class="assign-box" :class="taskParams.assignUserName !== ''?'assign-hover':''">-->
<!--                            <Input v-model="taskParams.assignUserName" readonly icon="ios-arrow-down" @click.native="onAssignClick" placeholder="请选择" style="width: 300px" />-->
<!--                            <a href="javascript:" class="item-close" v-if="taskParams.assignUserName !== ''" @click.stop="taskParams.assignUserId = '';taskParams.assignUserName=''"><Icon type="ios-close-circle"/></a>-->
<!--                        </div>-->
                        <Select v-model="taskParams.assignUserId" :loading="assignLoading" multiple filterable @on-open-change="onSearchClick" style="width: 300px">
                            <Option v-for="item in assignArray" :value="item.value" :key="item.value"><Avatar :src="item.profilePictureUrl?item.profilePictureUrl:defaultAvatar" size="small" />{{ item.label }}</Option>
                        </Select>
                    </li>
                    <li class="item">
                        <span class="label">学习人：</span>
<!--                        <div class="assign-box" :class="taskParams.assignUserName !== ''?'assign-hover':''">-->
<!--                            <Input v-model="taskParams.assignUserName" readonly icon="ios-arrow-down" @click.native="onAssignClick" placeholder="请选择" style="width: 300px" />-->
<!--                            <a href="javascript:" class="item-close" v-if="taskParams.assignUserName !== ''" @click.stop="taskParams.assignUserId = '';taskParams.assignUserName=''"><Icon type="ios-close-circle"/></a>-->
<!--                        </div>-->
                        <Select v-model="taskParams.learnUserIds" :loading="assignLoading" multiple filterable @on-open-change="onSearchClick" style="width: 300px">
                            <Option v-for="item in assignArray" :value="item.value" :key="item.value"><Avatar :src="item.profilePictureUrl?item.profilePictureUrl:defaultAvatar" size="small" />{{ item.label }}</Option>
                        </Select>
                    </li>
                    <li class="item">
                        <div class="task-modal-switch">
                            设置每次执行任务均从头开始
                            <div class="btn-switch">
                                <onSwitch v-model="taskParams.anew" :true-value="1" :false-value="0" size="small"></onSwitch>
                            </div>
                        </div>
                    </li>
<!--                    <li class="item">-->
<!--                        <span class="label required">生产厂家：</span>-->
<!--                        <Select v-model="newlyParams.manufacturer" clearable style="width:300px">-->
<!--                            <Option v-for="(manufacturer,key) in manufacturerArray" :value="manufacturer.id" :key="key">{{ manufacturer.name }}</Option>-->
<!--                        </Select>-->
<!--                        <a href="javascript:" class="add" @click="manufacturerVisible = true"><Icon custom="icon-manage" style="margin-right: 4px"/>管理生产厂家</a>-->
<!--                    </li>-->
                    <li class="item task-approve">
                        <h1 class="approve-title">作业许可申请</h1>
                        <div class="approve-permit">
                            <RadioGroup v-model="permitStatus">
                                <Radio :label="1">
                                    阅读并确认 <font class="permit-name">高风险作业项目许可审查</font>
                                </Radio>
                            </RadioGroup>
                        </div>
                        <div class="permit-content">
                            1.确认高风险作业项目计划已报送或临时审批。<br/>
                            2.确认施工作业人员已通过安全措施学习，身体状况良好，人员持有效证件上岗。<br/>
                            3.确认特种作业人员持有效证件，并在有效期内。<br/>
                            4.确认特种设备检验合格，并在有效期内。<br/>
                            5.确认配备的机械设备、工器具、通讯工具、安全用具及安全设施等检验合格，数量准备充足。
                        </div>
                        <h1 class="approve-title">作业风险描述</h1>
                        <div class="permit-desc">
                          <Input v-model="taskParams.riskDescription" :maxlength="100" show-word-limit type="textarea" placeholder="输入" />
                        </div>
                        <h1 class="approve-title">依此审批</h1>
                        <div class="approve-list">
                            <div class="approve-steps-item" :key="key" v-for="(approval,key) in taskParams.approvalUserIds">
                                <div class="steps-tail"></div>
                                <div class="steps-head"><a href="javascript:" @click="onRemoveApproval(key)"><Icon type="md-remove-circle" size="20"/></a></div>
                                <div class="steps-main">
                                    <div class="steps-title">{{ key+1 }}级审批人</div>
                                    <div class="steps-content">
                                        <a href="javascript:" class="steps-add"  @click="onAssignClick(key)" v-if="approval.id === ''"><Icon type="md-add" size="20"/></a>
                                        <div class="steps-name-box" v-if="approval.id !== ''">
                                            <span class="avatar" :style="'background-image:url('+(approval.profilePicture === ''?defaultAvatar:approval.profilePicture)+')'"></span>
                                            <div class="text">
                                                <span class="name">{{ approval.userName }}</span>
                                                <span class="tel">{{ approval.mobile }}</span>
                                                <a href="javascript:" class="clear" @click="onClearUser(key)"><Icon type="ios-close-circle" size="20"/></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="approve-steps-item">
                                <div class="steps-head"><a href="javascript:" @click="onAddApproval"><Icon type="ios-add-circle-outline" size="20" /></a></div>
                                <div class="steps-main">
                                    <div class="steps-title blue"><a href="javascript:" @click="onAddApproval">添加审批流程</a></div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="item layout-device-btn">
                        <Button type="primary" :loading="editLoading" @click="onSubmitCheck(1)">立即发布</Button>
                        <Button type="primary" :loading="editLoading" ghost @click="onSubmitCheck(0)">稍后发布</Button>
                        <Button @click="onBack">取 消</Button>
                    </li>
                </ul>
            </div>
        </div>

        <!--s: assign modal-->
        <Modal
            v-model="assignVisible"
            title="选择审批人"
            :width="428"
            class-name="task-modal-box"
            footer-hide>
            <div class="assign-modal">
                <div class="assign-search">
                    <Input v-model="assignValue" prefix="ios-search-outline" placeholder="搜索" @on-keyup="onAssignSearch"/>
                </div>
                <div class="assign-list">
                    <ul>
                        <li :key="key" v-for="(assignList,key) in assignSelectArray" :class="assignList.select?'active':''" @click="onAssign(assignList,key)">
                            <span class="avatar" :style="'background-image:url('+(assignList.profilePictureUrl === ''?defaultAvatar:assignList.profilePictureUrl)+')'"></span>
                            <div class="user-info">{{ assignList.label }} <font>{{ assignList.phone }}</font></div>
                        </li>
                    </ul>
                </div>
            </div>
        </Modal>
       <!--e: assign modal-->
<!--       <Spin fix v-if="flowLoading"></Spin>-->
        <Modal
            v-model="circleVisible"
            title="周期性任务设置"
            :width="500"
            footer-hide>
            <div class="circle-modal">
                <div class="circle-li">
                    <span class="label">第一次执行时间：</span>
                    <DatePicker type="datetime" :options="onOptions" v-model="taskParams.startTime" format="yyyy-MM-dd HH:mm" placeholder="请选择时间" style="width: 300px" @on-change="onOnlyTimeChange" @on-clear="onClearTime"/>
                </div>
                <div class="circle-li">
                    <span class="label">周期设置：</span>
                    <InputNumber :max="100" :min="1" v-model="taskParams.executeNum" />
                    <span class="company">次</span>
                    <Select v-model="taskParams.cycleType" style="width: 176px" @on-change="onCycleChange">
                        <Option v-for="(item,key) in taskCycleTypeArray" :value="item.typeCode" :key="key">{{ item.typeName }}</Option>
                    </Select>
                </div>
                <div class="circle-li circle-btn">
                    <Button @click="circleVisible = false">取 消</Button>
                    <Button type="primary" @click="circleVisible = false">确定</Button>
                </div>
            </div>
        </Modal>
    </div>
</template>
<script>
import EditTask from './editTask'
export default EditTask
</script>
<style lang="less">
@import "editTask";
</style>
