<template>
    <div class="layout-account-list">
        <!--s: Search 区域-->
        <div class="account-list-search">
            <div class="account-list-select">
                <div class="box">
                    <div class="box-list">
                        <span class="label">用户姓名：</span>
                        <Input type="text" v-model="accountListParams.name" clearable placeholder="用户姓名" style="width: 180px" />
                    </div>
                    <div class="box-list">
                        <span class="label">登录帐号：</span>
                        <Input type="text" v-model="accountListParams.mobile" clearable placeholder="登录帐号" style="width: 180px" />
                    </div>
                </div>
                <div class="box">
                    <Button @click="onReset">重置</Button>
                    <Button type="primary" @click="onSearch">查询</Button>
                    <Button type="primary" icon="ios-add" @click="onAddList">新增</Button>
                </div>
            </div>
        </div>
        <!--e: Search 区域-->
        <!--s: Data 区域-->
        <div class="device-list account-list">
            <Table :loading="accountLoading" stripe="stripe" :height="deviceListHeight" :columns="accountListColumns" :data="accountListArray.content" >
                <template slot="人员编号" slot-scope="{ row }">
                    {{ row.userCode || '/' }}
                </template>
                <template slot="操作" slot-scope="{ row }">
                    <div class="handle">
                        <a href="javascript:" @click="onEditList(row)">编辑</a>
                        <a href="javascript:" @click="onDelete(row.id)">删除</a>
                        <a href="javascript:" @click="onResetPassword(row.id)">重置密码</a>
                        <a href="javascript:" @click="onUpdatePassword(row.id)">修改密码</a>
                    </div>
                </template>
            </Table>
            <div class="device-list-page" v-if="accountListArray.content && accountListArray.content.length !== 0">
                <Page :total="accountListArray.totalSize" :current="accountListParams.currentPage" show-total show-sizer size="small" :page-size="accountListParams.displayNumber" @on-change="onSearchPage" @on-page-size-change="onSearchPageSize"/>
            </div>
        </div>
        <!--e: Data 区域-->

        <Modal
            v-model="accountModalVisible"
            :title="btnStatus?'新增':'编辑'"
            :closable="!speedSingleVisible"
            :mask-closable="!speedSingleVisible"
            width="900"
            footer-hide>
            <div class="account-modal-box">
                <div class="modal-li">
                    <div class="account-modal">
                        <span class="label required">用户姓名：</span>
                        <Input v-model="accountEditParams.name" maxlength="5" show-word-limit placeholder="请输入" style="width: 300px" />
                    </div>
                    <div class="account-modal">
                        <span class="label required">登录帐号：</span>
                        <Input v-model="accountEditParams.mobile" placeholder="请输入" style="width: 300px" />
                    </div>
                    <div class="account-modal">
                        <span class="label required">角色权限：</span>
                        <Select v-model="accountEditParams.roleId" style="width: 300px">
                            <Option v-for="item in roleListArray" :value="item.id" :key="item.id">{{ item.name }}</Option>
                        </Select>
                    </div>
                    <div class="account-modal" v-if="btnStatus">
                        <span class="label required password">密码：</span>
                        <Input v-model="accountEditParams.password" placeholder="请输入" style="width: 300px" />
                    </div>
                    <div class="account-modal">
                        <span class="label">人员编号：</span>
                        <Input v-model="accountEditParams.userCode" maxlength="20" show-word-limit placeholder="请输入" style="width: 300px" />
                    </div>
                    <div class="account-modal">
                        <span class="label">头像：</span>
                        <div class="account-structural-image">
                            <div class="box">
                                <div class="add-icon" v-if="!speedSingleVisible">
                                    <a href="javascript:">
                                        <Icon type="ios-add" :size="30"/>
                                    </a>
                                </div>
                                <div class="add-text" v-if="!speedSingleVisible">添加头像<br/>只能上传jpg/png/gif，且文件大小不超过5M</div>
                                <div class="progress" v-if="speedSingleVisible">
                                    <Progress :percent="complete" />
                                </div>
                                <input type="file" accept="image/*" v-if="!speedSingleVisible" ref="pathClear" class="file" @change="onAccountFileChange($event)">
                                <div class="file-image" v-if="accountEditParams.profilePictureUrl!==''">
                                    <span class="image" :style="'background-image:url('+accountEditParams.profilePictureUrl+')'"></span>
                                    <div class="handle">
                                        <div class="box">
                                            <a href="javascript:" @click="onImageAmplify(accountEditParams.name,accountEditParams.profilePictureUrl)"><Icon custom="look-big" :size="25"/></a>
                                            <a href="javascript:" @click="onClearImage"><Icon type="ios-trash-outline" :size="25"/></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-li">
                    <div class="account-modal">
                        <span class="label required password">岗位：</span>
                        <Input v-model="accountEditParams.position" maxlength="20" show-word-limit placeholder="请输入" style="width: 300px" />
                    </div>
                    <div class="account-modal">
                        <span class="label required">所属部门：</span>
                        <div class="account-menu-box" :style="btnStatus?'height:388px':'height:340px'">
                            <div class="list-item" :key="'child1'+key" v-for="(department,key) in departmentList">
                                <div class="item-thead">
                                    <Checkbox class="focus" :class="onAccountStyle(department)" :value="department.checked" @on-change="onCheckBox(department)"></Checkbox>
                                    <a href="javascript:" class="href"><Icon v-if="department.children.length !== 0" :type="department.visible?'md-arrow-dropdown':'md-arrow-dropright'" class="icon"  @click="department.visible = !department.visible"/></a>
                                    <p>{{ department.label }}</p>
                                </div>
                                <div class="item-body" v-if="department.visible">
                                    <div class="item-child" :key="'child2'+key" v-for="(children,key) in department.children">
                                        <div class="child-thead">
                                            <Checkbox class="focus" :class="onAccountStyle(children)" :value="children.checked" @on-change="onCheckBox(children,'two')"></Checkbox>
                                            <a href="javascript:" class="href"><Icon v-if="children.children.length !== 0"  @click="children.visible = !children.visible" :type="children.visible?'md-arrow-dropdown':'md-arrow-dropright'" class="icon" /></a>
                                            <p>{{ children.label }}</p>
                                        </div>
                                        <div class="child-body" v-if="children.visible">
                                            <ul>
                                                <li :key="'child3'+key" v-for="(child,key) in children.children">
                                                    <Checkbox class="focus" :value="child.checked" @on-change="onCheckBox(child,'three')"></Checkbox>
                                                    <p class="href"><Icon type="ios-copy-outline" class="icon"/>{{ child.label }}</p>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="device-footer">
                <Button :disabled="speedSingleVisible" @click="accountModalVisible = false">取 消</Button>
                <Button :disabled="speedSingleVisible" type="primary" :loading="editLoading" @click="onSubmitCheck">确 定</Button>
            </div>
        </Modal>
        <Modal
            v-model="updatePasswordVisible"
            title="修改密码"
            footer-hide>
            <div class="account-modal">
                <span class="label">新密码：</span>
                <Input v-model="updatePasswordParams.newPassword" type="password" password placeholder="请输入" style="width: 300px" />
            </div>
            <div class="account-modal">
                <span class="label">确认密码：</span>
                <Input v-model="updatePasswordParams.confirmPassword" type="password" password  placeholder="请输入" style="width: 300px" />
            </div>
            <div class="account-footer">
                <Button @click="updatePasswordVisible = false">取 消</Button>
                <Button type="primary" :loading="updatePasswordLoading" @click="onSubmitUpdatePasswordCheck">确 定</Button>
            </div>
        </Modal>
        <!--s: Modal 查看大图-->
        <ViewImage v-model="viewImageVisible" :imageName="imageName" :imageUrl="imageSrc" />
        <!--e: Modal 查看大图-->
    </div>
</template>
<script>
import AccountList from './list'
export default AccountList
</script>
<style lang="less">
@import "list";
</style>
