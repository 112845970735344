/**
 * Account 帐号管理
 * @type {{}}
 */
import Vue from "vue";
import { Input, Icon, Message, Modal, Button, Dropdown, DropdownMenu, DropdownItem,Select,Option } from "view-design";
Vue.component('Input', Input);
Vue.component('Icon', Icon);
Vue.component('Modal', Modal);
Vue.component('Button', Button);
Vue.component('Dropdown', Dropdown);
Vue.component('DropdownMenu', DropdownMenu);
Vue.component('DropdownItem', DropdownItem);
Vue.component('Select', Select);
Vue.component('Option', Option);
import {
    upgradeDeviceClassService,
     } from '@/service/device-class-service'
import {
    getUserDeptMenuList,
    addUserDeptInfo,
    updateUserDeptInfo,
    deleteUserDeptInfo
} from '@/service/user-service'
import { mapMutations, mapState } from "vuex";
import $event, { EVENT_NAME } from "@/utils/event-emitter";
const Account = {
    name:'Account',
    computed: {
        ...mapState({
            selectedMenuId: state => state.system.selectedMenuId, //选中的菜单Id
            menuVisible: state => state.system.menuVisible, //菜单显示状态
            deptId: state => state.userCenter.deptId, //选中的账户菜单Id
        }),
    },
    data() {
        return {
            windowWidth:window.innerWidth, //获取窗口宽度
            accountLoading:false, //帐号管理modal弹框 loading
            departmentName:'', //部门名称
            departmentId:'', //部门id
            btnStatus:false, //按钮状态
            departmentList:[], //部门列表
            companyName:'', //公司名称
            accountModalVisible:false, //帐号管理modal弹框可显示
        }
    },
    created() {
        if(this.selectedMenuId.length!==0){
            this.$router.push('/account/list')
        }
        if(this.selectedMenuId.length === 0){
            this.SET_SELECTED_MENU_ID([-1,'',''])
        }
        this.onUserDeptMenuList().then()
    },
    methods: {
        ...mapMutations('system',['SET_CLASSIFY_MENU_ARRAY','SET_SELECTED_MENU_ID']),
        ...mapMutations('userCenter',['SET_DEPT_ID']),
        /**
         * 跳转携带id
         * @param id
         */
        onLinks(value){
            this.SET_DEPT_ID(value)
            if(this.$route.path!=='/account/list'){
                this.$router.push('/account/list')
            }else{
                $event.$emit(EVENT_NAME.ACCOUNT_MENU_LIST)
            }
        },
        /**
         * 添加根结点部门
         */
        onAddDepartment(){
            this.btnStatus = true
            this.accountModalVisible = true
            this.departmentName = ''
            this.departmentId = ''
        },
        /**
         * 添加部门验证
         */
        onAddAccountCheck(){
            if(this.departmentName === ''){
                Message.error('请输入部门名称！')
                return;
            }
            this.btnStatus?this.onAddUserDeptInfo().then():this.onUpdateUserDeptInfo().then()

        },
        /**
         * 添加二级部门
         * @param id
         */
        onChildAddDepartment(id){
            this.btnStatus = true
            this.accountModalVisible = true
            this.departmentName = ''
            this.departmentId = id
        },
        /**
         * 修改部门
         * @param id
         */
        onUpdateDepartment(obj){
            this.btnStatus = false
            this.accountModalVisible = true
            this.departmentName = obj.label
            this.departmentId = obj.value
        },
        /**
         * 升级设备分类
         * @param id
         */
        onUpgradeDevice(id){
            this.onUpgradeDeviceClass(id).then()
        },
        /**
         * 删除部门
         * @param id
         */
        onDelDepartment(id){
            Modal.confirm({
                title: '提示',
                content: '<p>您确定要删除吗？</p>',
                loading:true,
                onOk: () => {
                    this.onDelUserDeptInfo(id).then()
                }
            });
        },
        /**
         * 添加部门
         * @returns {Promise<void>}
         */
        async onAddUserDeptInfo(){
            try {
                this.accountLoading = true
                let params = {
                    parentId:this.departmentId,
                    name:this.departmentName,
                }
                const { code } = await addUserDeptInfo(params)
                if(code === 'SUCCESS'){
                    this.departmentName = ''
                    this.departmentId = ''
                    Message.success('添加成功！')
                    this.onUserDeptMenuList().then()
                    this.accountModalVisible = false
                }else if(code === 'DEPT_NAME_REPEAT'){
                    Message.warning('请勿重复添加部门名称！')
                }else{
                    Message.error('请求异常，请联系管理员！')
                }
                this.accountLoading = false

            }catch (error){
                this.accountLoading = false
                console.log(error)
            }
        },
        /**
         * 修改部门
         * @returns {Promise<void>}
         */
        async onUpdateUserDeptInfo(){
            try {
                this.accountLoading = true
                let params = {
                    id:this.departmentId,
                    name:this.departmentName,
                }
                const { code } = await updateUserDeptInfo(params)
                if(code === 'SUCCESS'){
                    this.departmentId = ''
                    this.departmentName = ''
                    Message.success('修改成功！')
                    this.onUserDeptMenuList().then()
                    this.accountModalVisible = false
                }else if(code === 'DEPT_NAME_REPEAT'){
                    Message.warning('请勿重复添加部门名称！')
                }else{
                    Message.error('请求异常，请联系管理员！')
                }
                this.accountLoading = false

            }catch (error){
                this.accountLoading = false
                console.log(error)
            }
        },
        /**
         * 升级设备分类
         * @returns {Promise<void>}
         */
        async onUpgradeDeviceClass(id){
            try {
                let params = {
                    id:id,
                }
                const { code } = await upgradeDeviceClassService(params)
                if(code === 'SUCCESS'){
                    Message.success('升级成功！')
                    this.onDeviceClassAll().then()
                }else{
                    Message.error('请求异常，请联系管理员！')
                }
            }catch (error){
                console.log(error)
            }
        },
        /**
         * 删除部门
         * @returns {Promise<void>}
         */
        async onDelUserDeptInfo(id){
            try {
                this.accountLoading = true
                let params = {
                    id:id,
                }
                const { code } = await deleteUserDeptInfo(params)
                if(code === 'SUCCESS'){
                    Message.success('删除成功！')
                    this.onUserDeptMenuList().then()
                }else if(code === 'DEPT_HAVE_CHILD_NODE_FAIL'){
                    Message.warning('此部门下存在其他分组，不可删除！')
                }else if(code === 'DEPT_HAVE_USER_FAIL'){
                    Message.warning('此部门下存在员工，不可删除！')
                }else{
                    Message.error('请求异常，请联系管理员！')
                }
                this.accountLoading = false
                Modal.remove();
            }catch (error){
                Modal.remove();
                this.accountLoading = false
                console.log(error)
            }
        },
        /**
         * 获取所有部门列表
         */
        async onUserDeptMenuList() {
            try {
                let params = {
                    userId:'',
                }
                const { code,data } = await getUserDeptMenuList(params)
                if(code === 'SUCCESS'){
                    this.companyName = data.name
                    for(let i in data.childNode){
                        data.childNode[i].visible = true
                        if(data.childNode[i].childNode.length!==0){
                            for(let j in data.childNode[i].childNode){
                                data.childNode[i].childNode[j].visible = true
                            }
                        }
                    }
                    this.departmentList = this.onReplaceAttribute(data.childNode)
                }else{
                    Message.error('请求异常，请联系管理员！')
                }

            }catch (error){
                console.log(error)
            }
        },
        /**
         * 替换属性名
         * @param data
         */
        onReplaceAttribute(data){
            let item = []
            data.map(list => {
                let newData = {}
                newData.value = list.id;
                newData.label = list.name;
                newData.visible = list.visible;
                newData.children = list.childNode;
                newData.checked = list.checked;
                newData.icon = list.icon;
                newData.parentId = list.parentId;
                if(list.childNode){
                    newData.children = this.onReplaceAttribute(list.childNode)
                }
                item.push(newData)
            })
            return item
        },
    },
    destroyed () {
    }
}
export default Account
