/**
 * 设备信息服务
 * 生产厂家 / 设备型号
 */
import  request from '@/utils/request-utils'
import { appConfig } from '@/config/app-config';
const evnUrl = appConfig.domain.httpUrl
export * from './enum'
/**
 * get 请求 获取所有设备型号
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const getDeviceModelService = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/knowledge/equipment/model/list/all'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}
/**
 * get 请求 获取所有生产厂家
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const getManufacturerService = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/knowledge/equipment/manufacturer/list/all'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * post 请求 添加设备型号
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const addDeviceModelService = async (params) => {
    try {
        const { data } = await request.postJson(evnUrl.concat('/knowledge/equipment/model/add'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}
/**
 * post 请求 编辑设备型号
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const editDeviceModelService = async (params) => {
    try {
        const { data } = await request.postJson(evnUrl.concat('/knowledge/equipment/model/edit'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * post 请求 删除设备型号
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const deleteDeviceModelService = async (params) => {
    try {
        const { data } = await request.postJson(evnUrl.concat('/knowledge/equipment/model/delete'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}


/**
 * post 请求 添加生产厂家
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const addManufacturerService = async (params) => {
    try {
        const { data } = await request.postJson(evnUrl.concat('/knowledge/equipment/manufacturer/add'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * post 请求 删除生产厂家
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const deleteManufacturerService = async (params) => {
    try {
        const { data } = await request.postJson(evnUrl.concat('/knowledge/equipment/manufacturer/delete'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * post 请求 编辑生产厂家
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const editManufacturerService = async (params) => {
    try {
        const { data } = await request.postJson(evnUrl.concat('/knowledge/equipment/manufacturer/edit'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * get 请求 根据设备型号名称查询生产厂家
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const getNameManufacturerService = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/knowledge/equipment/manufacturer/list/name?name='+params.name))
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}
/**
 * get 请求 根据设备型号Id查询生产厂家
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const getIdManufacturerService = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/knowledge/equipment/manufacturer/list?modelId='+params.id))
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * post 请求 添加设备信息
 * @param formData
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const addDeviceInfoService = async (params) => {
    try {
        const { data } = await request.postJson(evnUrl.concat('/knowledge/equipment/add'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}
/**
 * post 请求 修改设备信息
 * @param formData
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const updateDeviceInfoService = async (params) => {
    try {
        const { data } = await request.postJson(evnUrl.concat('/knowledge/equipment/update'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * post 请求 修改设备简介
 * @param formData
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const updateDeviceInfoIntroService = async (params) => {
    try {
        const { data } = await request.postJson(evnUrl.concat('/knowledge/equipment/intro/edit'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}
/**
 * post 请求 修改技术参数
 * @param formData
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const updateDeviceInfoParamService = async (params) => {
    try {
        const { data } = await request.postJson(evnUrl.concat('/knowledge/equipment/param/edit'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}
/**
 * post 请求 修改工作原理
 * @param formData
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const updateDeviceInfoPrincipleService = async (params) => {
    try {
        const { data } = await request.postJson(evnUrl.concat('/knowledge/equipment/principle/edit'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * get 请求 查询设备列表
 * @param formData
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const getDeviceListService = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/knowledge/equipment/list?equipmentClassificationId='+params.deviceType+'&manufacturerId='+params.manufacturer+'&name='+params.search+'&size='+params.displayNumber+'&page='+params.currentPage))
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}


/**
 * post 请求 删除设备信息
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const delDeviceInfoService = async (params) => {
    try {
        const { data } = await request.postJson(evnUrl.concat('/knowledge/equipment/delete'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * get 请求 查询设备信息
 * @param formData
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const getDeviceInfoService = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/knowledge/equipment/materia/query/info?id='+params.id+'&dataType='+params.dataType+'&partsId='+params.partsId))
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * get 请求 通过id查询设备信息
 * @param formData
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const getDeviceInfoIdService = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/knowledge/equipment/id?id='+params.id))
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}
/**
 * get 请求 通过id查询结构部件数量信息
 * @param formData
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const getStructuralInfoIdService = async (params) => {
    try {
        const { data } = await request.postJson(evnUrl.concat('/knowledge/equipment/parts/id'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * post 请求 添加资料文件
 * @param formData
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const addDeviceDataService = async (params) => {
    try {
        const { data } = await request.postJson(evnUrl.concat('/knowledge/equipment/materia/add'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * post 请求 删除资料文件
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const delDeviceDataService = async (params) => {
    try {
        const { data } = await request.postJson(evnUrl.concat('/knowledge/equipment/materia/delete/all'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}
/**
 * post 请求 删除资料文件
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const delFileDataService = async (params) => {
    try {
        const { data } = await request.postJson(evnUrl.concat('/knowledge/equipment/file/materia/delete'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}
/**
 * post 请求 批量下载文件资料
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const getFileDownLoadService = async (params) => {
    try {
        const { data } = await request.postDownLoad(evnUrl.concat('/knowledge/equipment/file/materia/download'),params,'login')
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}
/**
 * post 请求 删除电器图册文件
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const delDeviceAtlasService = async (params) => {
    try {
        const { data } = await request.postJson(evnUrl.concat('/knowledge/equipment/electric/atlas/delete/all'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}
/**
 * post 请求 删除故障分析
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const delFailureDataService = async (params) => {
    try {
        const { data } = await request.postJson(evnUrl.concat('/knowledge/equipment/failure/delete'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * post 请求 删除可能原因
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const delReasonDataService = async (params) => {
    try {
        const { data } = await request.postJson(evnUrl.concat('/knowledge/equipment/failure/delete/reason'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * post 请求 重新上传资料
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const updateDeviceDataService = async (params) => {
    try {
        const { data } = await request.postJson(evnUrl.concat('/knowledge/equipment/materia/reupload'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * post 请求 上传解决方案资源文件
 * @param formData
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const updateDeviceReasonDataService = async (params) => {
    try {
        const { data } = await request.postUpLoad(evnUrl.concat('/knowledge/resources/upload'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * post 请求 故障分析移动排序
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const getFailureMoveService = async (params) => {
    try {
        const { data } = await request.postJson(evnUrl.concat('/knowledge/equipment/analysis/move'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}


/**
 * post 请求 添加故障分析
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const addFailureService = async (params) => {
    try {
        const { data } = await request.postJson(evnUrl.concat('/knowledge/equipment/failure/add'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}
/**
 * post 请求 编辑故障分析
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const editFailureService = async (params) => {
    try {
        const { data } = await request.postJson(evnUrl.concat('/knowledge/equipment/failure/edit'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * post 请求 添加可能原因
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const addReasonService = async (params) => {
    try {
        const { data } = await request.postJson(evnUrl.concat('/knowledge/equipment/failure/save/reason'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * post 请求 修改可能原因
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const editReasonService = async (params) => {
    try {
        const { data } = await request.postJson(evnUrl.concat('/knowledge/equipment/failure/edit/reason'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * post 请求 可能原因移动排序
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const getReasonMoveService = async (params) => {
    try {
        const { data } = await request.postJson(evnUrl.concat('/knowledge/equipment/analysis/reason/move'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * get 请求 下载故障分析模版
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const getFailureDownLoadService = async (params) => {
    try {
        const { data } = await request.postDownLoad(evnUrl.concat('/knowledge/equipment/analysis/export'),params,'login')
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}
/**
 * get 请求 导出故障分析数据
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const getFailureExportService = async (params) => {
    try {
        const { data } = await request.postDownLoad(evnUrl.concat('/knowledge/equipment/analysis/export/data'),params,'login')
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * post 请求 导入故障分析数据
 * @param formData
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const addFailureUpLoadService = async (params,config) => {
    try {
        const { data } = await request.postUpLoadSpeed(evnUrl.concat('/knowledge/equipment/analysis/import'),params,config)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}
  /**
   * get 请求 根据设备id获取结构部件列表
   * @param url
   * @param params
   * @returns {Promise<*>}
   */
  export const getStructuralDeviceIdService = async (params) => {
      try {
          const { data } = await request.get(evnUrl.concat('/knowledge/equipment/parts/list?id='+params.id))
          return data
      } catch (error) {
          return Promise.reject(error)
      }
  }
/**
 * post 请求 添加结构部件
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const addStructuralService = async (params) => {
    try {
        const { data } = await request.postJson(evnUrl.concat('/knowledge/equipment/parts/add'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}
/**
 * post 请求 编辑结构部件
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const editStructuralService = async (params) => {
    try {
        const { data } = await request.postJson(evnUrl.concat('/knowledge/equipment/parts/edit'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * post 请求 删除结构部件
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const delStructuralService = async (params) => {
    try {
        const { data } = await request.postJson(evnUrl.concat('/knowledge/equipment/parts/delete/all'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}
/**
 * post 请求 升级结构部件
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const upgradeStructuralService = async (params) => {
    try {
        const { data } = await request.postJson(evnUrl.concat('/knowledge/equipment/parts/upgrade'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}
/**
 * post 请求 根据id获取结构部件信息
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const getStructuralIdService = async (params) => {
    try {
        const { data } = await request.postJson(evnUrl.concat('/knowledge/equipment/parts/id'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}
/**
 * post 请求 修改电气列表名称
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const updateElectricListNameService = async (params) => {
    try {
        const { data } = await request.postJson(evnUrl.concat('/knowledge/equipment/electric/atlas/updateName'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}
/**
 * post 请求 修改文件资料名称
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const updateFileListNameService = async (params) => {
    try {
        const { data } = await request.postJson(evnUrl.concat('/knowledge/equipment/file/materia/edit'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * post 请求 修改电气图册状态
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const updateElectricStatusService = async (params) => {
    try {
        const { data } = await request.postJson(evnUrl.concat('/knowledge/equipment/electric/atlas/publish'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * post 请求 删除电气图册
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const delElectricalListService = async (params) => {
    try {
        const { data } = await request.postJson(evnUrl.concat('/knowledge/equipment/electric/atlas/delete'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}
/**
 * post 请求 获取电气图册信息
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const getElectricalInfoService = async (params) => {
    try {
        const { data } = await request.postJson(evnUrl.concat('/knowledge/equipment/electric/atlas/detail'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * post 请求 编辑电气图册数据
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const editElectricalService = async (params) => {
    try {
        const { data } = await request.postJson(evnUrl.concat('/knowledge/equipment/electric/atlas/edit'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * post 请求 pdf保存
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const addPdfElectricalService = async (params) => {
    try {
        const { data } = await request.postJson(evnUrl.concat('/knowledge/equipment/pdf/add'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * post 请求 pdf转换进度查询
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const getPdfElectricalProgressService = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/knowledge/equipment/pdf/parsing/progress?fileUrl='+params.fileUrl))
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}
/**
 * post 请求 文件上传进度查询
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const getFileUploadProgressService = async (params) => {
    try {
        const { data } = await request.postJson(evnUrl.concat('/storage/upload/queryFileGroupUploadProgress'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * post 请求 pdf转换
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const convertPdfElectricalService = async (params) => {
    try {
        const { data } = await request.postJson(evnUrl.concat('/knowledge/equipment/pdf/convert'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}
/**
 * post 请求 图册重命名
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const editElectricalAtlasRenameService = async (params) => {
    try {
        const { data } = await request.postJson(evnUrl.concat('/knowledge/equipment/mechanical/atlas/updateName'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}
/**
 * post 请求 添加资料文件夹
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const addFileService = async (params) => {
    try {
        const { data } = await request.postJson(evnUrl.concat('/knowledge/equipment/file/materia/add'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}
/**
 * get 请求 获取下级资料文件列表
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const getFileChildService = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/knowledge/equipment/file/materia/child?id='+params.id))
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

