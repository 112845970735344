<template>
    <div class="layout-account">

        <!--s: Account menu-->
        <div class="account-menu">
            <div class="menu-system">
                <div class="item">
                    <div class="menu-name"><a href="javascript:" :class="deptId === '' || deptId === undefined?'active':''" @click="onLinks('')">{{ companyName }}</a></div>
                    <a href="javascript:" @click="onAddDepartment" class="thead-btn"><Icon type="ios-add-circle-outline" /></a>
                </div>
            </div>
            <div class="menu-list">
                <div class="list-item" :key="'child1'+key" v-for="(department,key) in departmentList">
                    <div class="item-thead">
                        <a href="javascript:">
                            <Icon :type="department.visible?'md-arrow-dropdown':'md-arrow-dropright'" class="icon"  @click="department.visible = !department.visible"/>
                            <p :class="deptId === department.value?'active':''" @click="onLinks(department.value)">{{ department.label }}</p>
                        </a>
                        <div class="child-system">
                            <Dropdown :transfer="true">
                                <a href="javascript:">
                                    <Icon custom="more"/>
                                </a>
                                <DropdownMenu slot="list" class="system-menu">
                                    <DropdownItem>
                                        <a href="javascript:" @click="onUpdateDepartment(department)">编辑</a>
                                    </DropdownItem>
                                    <DropdownItem>
                                        <a href="javascript:" @click="onDelDepartment(department.value)">删除</a>
                                    </DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                            <a href="javascript:" class="href" @click="onChildAddDepartment(department.value)"><Icon type="ios-add-circle-outline" class="icon"/></a>
                        </div>
                    </div>
                    <div class="item-body" v-if="department.visible">
                        <div class="item-child" :key="'child2'+key" v-for="(children,key) in department.children">
                            <div class="child-thead" :style="children.children.length === 0?'padding-left:20px':''">
                                <a href="javascript:" class="href">
                                    <Icon v-if="children.children.length !== 0"  @click="children.visible = !children.visible" :type="children.visible?'md-arrow-dropdown':'md-arrow-dropright'" class="icon" />
                                    <p :class="deptId === children.value?'active':''" @click="onLinks(children.value)">{{ children.label }}</p>
                                </a>
                                <div class="child-system">
                                    <Dropdown :transfer="true">
                                        <a href="javascript:">
                                            <Icon custom="more"/>
                                        </a>
                                        <DropdownMenu slot="list" class="system-menu">
                                            <DropdownItem>
                                                <a href="javascript:" @click="onUpdateDepartment(children)">编辑</a>
                                            </DropdownItem>
                                            <DropdownItem>
                                                <a href="javascript:" @click="onDelDepartment(children.value)">删除</a>
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>
                                    <a href="javascript:" class="href" @click="onChildAddDepartment(children.value)"><Icon type="ios-add-circle-outline" class="icon"/></a>
                                </div>
                            </div>
                            <div class="child-body" v-if="children.visible">
                                <ul>
                                    <li :key="'child3'+key" v-for="(child,key) in children.children">
                                        <a href="javascript:" class="href" :class="deptId === child.value?'active':''" @click="onLinks(child.value)"><Icon type="ios-copy-outline" class="icon"/>{{ child.label }}</a>
                                        <div class="child-system">
                                            <Dropdown :transfer="true">
                                                <a href="javascript:">
                                                    <Icon custom="more"/>
                                                </a>
                                                <DropdownMenu slot="list" class="system-menu">
<!--                                                    <DropdownItem>-->
<!--                                                        <a href="javascript:" @click="onUpgradeDevice(child.value)">升级</a>-->
<!--                                                    </DropdownItem>-->
                                                    <DropdownItem>
                                                        <a href="javascript:" @click="onUpdateDepartment(child)">编辑</a>
                                                    </DropdownItem>
                                                    <DropdownItem>
                                                        <a href="javascript:" @click="onDelDepartment(child.value)">删除</a>
                                                    </DropdownItem>
                                                </DropdownMenu>
                                            </Dropdown>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--e: Account menu-->

        <!--s: Account content-->
        <div class="account-content" :style="menuVisible?'width: calc(100% - 560px);':'width: calc(100% - 310px);'">
            <router-view />
        </div>
        <!--e: Account content-->

        <Modal
            v-model="accountModalVisible"
            :title="btnStatus?'添加部门':'修改部门'"
            footer-hide>
            <div class="device-modal">
                <span class="label">名称：</span>
                <Input v-model="departmentName" maxlength="10" show-word-limit placeholder="请输入" style="width: 300px" />
            </div>
            <div class="device-footer">
                <Button @click="accountModalVisible = false">取 消</Button>
                <Button type="primary" :loading="accountLoading" @click="onAddAccountCheck">确 定</Button>
            </div>
        </Modal>

    </div>
</template>
<script>
import Account from './account'
export default Account
</script>
<style lang="less">
@import "account";
</style>
