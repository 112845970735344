export default {
    namespaced: true,
    state: {
        userInfo:{}, //用户信息
        accountStatus:'', //账户状态
        deptId:'', //部门id
    },
    mutations: {
        /**
         * 用户信息
         * @param state
         * @param userInfo
         * @constructor
         */
        SET_USER_INFO (state,userInfo) {
            state.userInfo = userInfo;
        },
        /**
         * 账户状态
         * @param state
         * @param accountStatus
         * @constructor
         */
        SET_ACCOUNT_STATUS (state,accountStatus){
            state.accountStatus = accountStatus
        },
        /**
         * 部门id
         * @param state
         * @param accountStatus
         * @constructor
         */
        SET_DEPT_ID (state,deptId){
            state.deptId = deptId
        }
    },
}
