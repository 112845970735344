<template>
    <div class="layout-process-list" :class="menuVisible ? 'layout-visible': ''">
        <!--s: Search 区域-->
        <div class="device-list-search-config">
            <div class="device-list-select">
                <div class="box">
<!--                    <div class="box-list">-->
<!--                        <span class="label">时间：</span>-->
<!--                        <Select v-model="processListParams.sortType" clearable style="width: 100px">-->
<!--                            <Option v-for="(date,key) in processDate" :value="date.value" :key="key">{{ date.label }}</Option>-->
<!--                        </Select>-->
<!--                    </div>-->
                    <div class="box-list">
                        <span class="label">发布状态：</span>
                        <Select v-model="processListParams.status" clearable style="width: 100px">
                            <Option v-for="(status,key) in processStatus" :value="status.value" :key="key">{{ status.label }}</Option>
                        </Select>
                    </div>
                    <div class="box-list">
                        <Button type="primary" @click="onSearch" style="margin-left: 40px">查询</Button>
                    </div>
                </div>
                <div class="box">
                    <Button type="primary" icon="ios-add" @click="onAddList">新建</Button>
                </div>
            </div>
        </div>
        <!--e: Search 区域-->
        <!--s: Data 区域-->
        <div class="process-list">
            <div class="box" :style="'height:'+processHeight+'px'">
                <ul>
                    <li :key='key' v-for="(processList,key) in processListArray.content">
                        <div class="process-card" @click="onEditLinks(processList)">
                            <ul>
                                <li>流程编号：{{ processList.flowCode }} <font class="badge" :class="processList.status === 0?'red':'green'">{{ processList.status === 0?'未发布':'已发布' }}</font></li>
                                <li>创建日期：{{ processList.createTime }}</li>
                                <li>创建人：{{ processList.createUser }}</li>
                                <li>任务引用：{{ processList.useNum }}</li>
                            </ul>
                            <div class="edit" v-if="processList.publishUseNum === 0" @click.stop>
                                <Dropdown placement="bottom-end">
                                    <a href="javascript:void(0)" class="edit-size">
                                        <Icon type="ios-more"></Icon>
                                    </a>
                                    <DropdownMenu slot="list">
<!--                                        <DropdownItem v-if="processList.useNum > 0" @click.native="onEditLinks(processList)">查看</DropdownItem>-->
                                        <DropdownItem v-if="processList.publishUseNum < 1 && processList.status === 0" @click.native="onUpdateNameStatus(key,processList)">重命名</DropdownItem>
                                        <DropdownItem v-if="processList.status === 0" @click.native="onEditLinks(processList)">编辑</DropdownItem>
                                        <DropdownItem v-if="processList.status === 0" @click.native="onDelete(processList)">删除</DropdownItem>
                                        <DropdownItem v-if="processList.publishUseNum < 1 && processList.status === 1"  @click.native="onRelease(processList.id,0)">撤回</DropdownItem>
                                        <DropdownItem v-if="processList.status === 0"  @click.native="onRelease(processList.id,1)">发布</DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            </div>
                        </div>
                        <div class="card-name-box">
                            <p class="name" v-if="!processList.nameStatus">{{ processList.flowName }}</p>
                            <Input ref="flowName" v-model="processList.flowName" maxlength="20" v-if="processList.nameStatus" @on-blur="onUpdateName(processList.id,key)" @on-enter="$event.target.blur()" placeholder="请输入工作流名称" class="name" />
                        </div>
                    </li>
                </ul>
                <Spin fix v-if="processLoading"></Spin>
            </div>
            <div class="device-list-page" v-if="processListArray.content && processListArray.content.length !== 0">
                <Page :total="processListArray.totalSize" :current="processListArray.currentPage" show-total show-sizer size="small" :page-size="processListParams.displayNumber" :page-size-opts="[6,9,12,15]" @on-change="onSearchPage" @on-page-size-change="onSearchPageSize"/>
            </div>
        </div>
        <!--e: Data 区域-->
    </div>
</template>
<script>
import ProcessList from './list'
export default ProcessList
</script>
<style lang="less">
@import "list";
</style>
