import * as imageConversion from 'image-conversion'
/**
 * 大小转化B KB MB GB的转化方法
 * @param limit
 * @returns {string}
 */
export const renderSize = (filesize) => {
    if(null==filesize||filesize==''){
        return "0 Bytes";
    }
    var unitArr = new Array("Bytes","KB","MB","GB","TB","PB","EB","ZB","YB");
    var index=0;
    var srcsize = parseFloat(filesize);
    index=Math.floor(Math.log(srcsize)/Math.log(1024));
    var size =srcsize/Math.pow(1024,index);
    size=size.toFixed(2);//保留的小数位数
    return size+unitArr[index];
}

/**
 *  验证手机号
 * @param phone
 * @returns {boolean}
 */
export const validatePhone = (phone) => {
    let phoneRe = /^1(3|4|5|6|7|8|9)\d{9}$/;
    return phoneRe.test(phone);
}

/**
 * 验证汉字和数字
 * @param text
 * @returns {boolean}
 */
export const validateCharNumber = (text) => {
    let testRe = /^[0-9\u4e00-\u9fa5]+$/
    return testRe.test(text)
}

//保存二维码
export const saveCode = (data,image) => {
    const fileName = data + '.png'
    let img = image
    //document.getElementById('qrcode').getElementsByTagName('img')[0].src
    let aLink = document.createElement('a')
    let blob = base64ToBlob(img)
    let evt = document.createEvent('HTMLEvents')
    evt.initEvent('click', true, true) // initEvent 不加后两个参数在FF下会报错  事件类型，是否冒泡，是否阻止浏览器的默认行为
    aLink.download = fileName
    aLink.href = URL.createObjectURL(blob)
    // aLink.dispatchEvent(evt);
    aLink.click()
}
/**
 * 下载图片
 * @param url
 * @param name
 */
export const saveImage = (url,name) => {
    let image = new Image()
    image.setAttribute('crossOrigin', 'anonymous')
    image.src = url
    image.onload = () => {
        let canvas = document.createElement('canvas')
        canvas.width = image.width
        canvas.height = image.height
        let ctx = canvas.getContext('2d')
        ctx.drawImage(image, 0, 0, image.width, image.height)
        canvas.toBlob((blob) => {
            let url = URL.createObjectURL(blob)
            let eleLink = document.createElement('a')
            eleLink.download = name
            eleLink.href = url
            eleLink.click()
            eleLink.remove()
            // 用完释放URL对象
            URL.revokeObjectURL(url)
        })
    }
}
/**
 * 下载文件
 * @param url
 * @param name
 */
export const saveFile = (url,name) => {
    var eleLink = document.createElement('a');
    eleLink.download = name;
    eleLink.style.display = 'none';
    // 下载内容转变成blob地址
    eleLink.href = url;
    // 触发点击
    document.body.appendChild(eleLink);
    eleLink.click();
    // 然后移除
    document.body.removeChild(eleLink);
}
//base64转blob
export const base64ToBlob = (code) => {
    let parts = code.split(';base64,')
    let contentType = parts[0].split(':')[1]
    let raw = window.atob(parts[1])
    let rawLength = raw.length
    let uInt8Array = new Uint8Array(rawLength)
    for (let i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i)
    }
    return new Blob([uInt8Array], { type: contentType })
}

/**
 * 返回日期插件
 * @returns {string}
 */
export const dataUnit = () => {
    let newData = new Date();
    let year = newData.getFullYear(); //获取完整的年份(4位)
    let mouth = newData.getMonth()+1;
    let data = newData.getDate();
    mouth<10?mouth = '0'+mouth:mouth
    data<10?data = '0'+data:data
    return year+''+mouth+''+data
}

/**
 * 返回日期时间插件
 * @returns {string}
 */
export const dataTimeUnit = () => {
    let newData = new Date();
    let hours = newData.getHours()
    let minutes = newData.getMinutes()
    hours<10?hours = '0'+hours:hours
    minutes<10?minutes = '0'+minutes:minutes
    return newData.toISOString().split('T')[0]+' '+hours+':'+minutes
}

/**
 * 返回后一天插件
 * @returns {string}
 */
export const nextDay = () => {
    const date = new Date(new Date().toISOString().split('T')[0]);
    date.setDate(date.getDate() + 1);
    let newData = new Date();
    let hours = newData.getHours()
    let minutes = newData.getMinutes()
    hours<10?hours = '0'+hours:hours
    minutes<10?minutes = '0'+minutes:minutes
    return date.toISOString().split('T')[0]+' '+hours+':'+minutes
}

/**
 * 处理下载接口返回的文件流数据
 * @param {*} res http请求返回数据
 */
export const downloadFile = (row,data,type) => {
    let blob
    if(type === 'excel'){
        blob = new Blob([data],{type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'})
    }else{
         blob = new Blob([data],{type: 'application/pdf'})
    }
    if ('download' in document.createElement('a')) { // 非IE下载
        const link = document.createElement('a')
        if(type === 'excel'){
            link.download = dataUnit()+'-'+row.flowName+'-'+row.recordCode+'.xlsx'
        }else{
            link.download = row.recordCode
        }
        link.style.display = 'none'
        link.href = URL.createObjectURL(blob)
        document.body.appendChild(link)
        link.click()
        URL.revokeObjectURL(link.href) // 释放URL 对象
        document.body.removeChild(link)
    } else { // IE10+下载
        if(type === 'excel'){
            navigator.msSaveBlob(blob, dataUnit()+'-'+row.flowName+'-'+row.recordCode+'.xlsx')
        }else{
            navigator.msSaveBlob(blob, row.recordCode)
        }
    }
}

export const downLoadBatch = (fileName,data) =>{
    const blob =  new Blob([data],{type:'content-type'});
    const url = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.download = dataUnit()+'-'+fileName+'.zip'
    link.click()
    window.URL.revokeObjectURL(url)
}

/**
 *  随机数
 * @returns {number}
 */
export const onRandomPlatform = () => {
    let random = _s1().toUpperCase()
    return random;
}

/**
 * 生成uuid
 * @returns {string}
 * @private
 */
let _s1 = () => {
    return (((1 + Math.random()) * 0x1000)|0).toString(16).substring(1)
}
export const onGuid = () => {
    let uuid = (_s1() + _s1()+'-'+_s1()+'-'+_s1()+'-'+_s1()+'-'+_s1()+_s1()+_s1())
    return uuid
}

/**
 * 相邻数组移动位置
 * @param array
 * @param index1
 * @param index2
 * @returns {*}
 */
export const swapArray = (array, index1, index2) =>{
    array[index1] = array.splice(index2, 1, array[index1])[0];
    return array;
}

/**
 * 压缩图片
 * @param file
 * @param size
 */
export const compressImage = (file,size) =>{

    return new Promise((resolve) => {
// 压缩到100KB,这里的100就是要压缩的大小,可自定义
        imageConversion.compressAccurately(file, size).then(res => {
            let files = new File ([res],file.name,{type:file.type,lastModified:Date.now()})
            resolve(files);
        });
    }).catch((e)=>{
        console.log(e)
    })

}
