<template>
    <div class="layout-record-list">
        <!--s: Search 区域-->
        <div class="device-list-search">
            <div class="device-list-select">
                <div class="box">
                    <div class="box-list-record">
                        <span class="label">任务名称：</span>
                        <Input v-model="recordListParams.tasksName" clearable placeholder="任务名称" style="width: 100px" />
                    </div>
<!--                    <div class="box-list-record">-->
<!--                        <span class="label">任务类型：</span>-->
<!--                        <Select v-model="recordListParams.tasksType" clearable style="width: 100px">-->
<!--                            <Option v-for="(type,key) in tasksType" :value="type.value" :key="key">{{ type.label }}</Option>-->
<!--                        </Select>-->
<!--                    </div>-->
                    <div class="box-list-record">
                        <span class="label">执行状态：</span>
                        <Select v-model="recordListParams.status" clearable style="width: 100px">
                            <Option v-for="(status,key) in recordStatus" :value="status.value" :key="key">{{ status.label }}</Option>
                        </Select>
                    </div>
                    <div class="box-list-record">
                        <span class="label">引用流程：</span>
                        <Input v-model="recordListParams.flowName" clearable placeholder="引用流程" style="width: 100px" />
                    </div>
                </div>
                <div class="box">
                    <Button @click="onReset">重置</Button>
                    <Button type="primary" @click="onSearch">查询</Button>
                </div>
            </div>
        </div>
        <!--e: Search 区域-->
        <!--s: Data 区域-->
        <div class="device-list record-list">
            <Table :loading="recordLoading" :height="deviceListHeight" stripe="stripe" :columns="recordListColumns" :data="recordListArray.content" >
                <template slot="状态" slot-scope="{ row }">
                    <font v-if="row.status === 2" class="yellow">进行中</font>
                    <font v-if="row.status === 1" class="green">已完成</font>
                    <font v-if="row.status === 0" class="grey">未完成</font>
                    <!--                        <font v-if="row.status === 2" class="red">待审批</font>-->
                </template>
                <template slot="执行人" slot-scope="{ row }">
                    <Poptip placement="bottom-start">
                        <Button class="user-item" v-if="row.executorUser"> {{ row.taskExecutiveMembers.length === 1?row.taskExecutiveMembers[0].userName:row.taskExecutiveMembers[0].userName+'...' }}</Button>
                        <template #content v-if="row.executorUser">
                            <div class="record-content-box">
                                <div class="user-item-box" :key="key" v-for="(userList,key) in row.taskExecutiveMembers">
                                    <span class="avatar" :style="'background-image:url('+userList.profilePicture+')'"></span>
                                    <div class="box-r">
                                        <span class="name">{{ userList.userName }}</span>
                                        <span class="tel">{{ userList.mobile }}</span>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </Poptip>
                    {{ !row.executorUser?'/':'' }}
                </template>
                <template slot="开始时间" slot-scope="{ row }">
                    {{ row.beginTime?row.beginTime.replace(/:..$/, '') : '/'}}
                </template>
                <template slot="完成时间" slot-scope="{ row }">
                    {{ row.finishTime?row.finishTime.replace(/:..$/, '') : '/'}}
                </template>
                <template slot="操作" slot-scope="{ row }">
                    <div class="handle">
                        <a href="javascript:" class="blue" @click="onQueryLink(row)">查看</a>
                        <a href="javascript:" class="blue" v-if="row.status === 1" @click="onDownLoad(row)">生成报告</a>
                        <span class="handle-grey" v-if="row.status === 0">生成报告</span>
                        <a href="javascript:" @click="onDelete(row.id)">删除</a>
                    </div>
                </template>
            </Table>
            <div class="device-list-page" v-if="recordListArray.content && recordListArray.content.length !== 0">
                <Page :total="recordListArray.totalSize" :current="recordListArray.currentPage" size="small" show-total show-sizer :page-size="recordListParams.displayNumber" @on-change="onSearchPage" @on-page-size-change="onSearchPageSize"/>
            </div>
        </div>
        <!--e: Data 区域-->

    </div>
</template>
<script>
import RecordList from './list'
export default RecordList
</script>
<style lang="less">
@import "list";
</style>
